<template>
  <base-layout>
    <template v-if="$acl.canView('riders')">
      <details-page-title
        :title="$t('components.riderDetailsManagement.header.title')"
        :details-id="id"
        @activate="UpdateStatus('A')"
        @deactivate="UpdateStatus('D')"
      >
        <!-- <status-dropdown
        :endpoint="getStatusEndpoint"
        method="PUT"
        :statusCurrent="getStatus"
        statusKeyActivate="user_status"
        statusValueActivate="A"
        statusKeyDeactivate="user_status"
        statusValueDeactivate="D"
        :statusFirstEnabled="true"
        statusKeyFirst="user_status"
        statusValueFirst="H"
      /> -->

        <!-- <LastTripStatusButton /> -->
        <LastTripStatusDropdown :lastTrip="getLastTrip" />
      </details-page-title>

      <details-page-header
        :name="getName"
        :badge="getBadge"
        :badgeVariant="getBadgeVariant"
        :status="getStatus"
        :avatar="getAvatar"
        :email="getEmail"
        :phone="getPhone"
        :tabs="tabs"
        :riderCardInfo="getRiderSummary"
        :symbol="getSymbol"
        :isBlacklisted="getBlacklistedStatus"
      />

      <div class="min-h-screen">
        <router-view />
      </div>
    </template>
    <div v-else class="py-5 font-bold text-center text-gray-600">
      You don't have permission to see data. Please Ask your admin to give you
      permission for this screen.
    </div>
  </base-layout>
</template>

<script>
import xMan from '@/utils/xMan'

import { RiderUserConfig } from '@/config/RiderUserConfig'
import { StatusConfig } from '@/config/StatusConfig'
import { RiderResource } from '@/resources/RiderResource'

import BaseLayout from '@/views/shared/BaseLayout'
import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import DetailsPageHeader from '@/components/ui/DetailsPageHeader.vue'
// import StatusDropdown from "@/components/dropdown/StatusDropdown";

import { mapGetters } from 'vuex'
import LastTripStatusDropdown from '@/composites/rider/shared/LastTripStatusDropdown'
import { EventBus } from '@/utils'

export default {
  name: 'ViewOrganizationUser',
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
    LastTripStatusDropdown,
    // StatusDropdown
  },
  async created() {
    await this.$store.dispatch('riderdetails/fetchData', this.id)
    this.header = this.$store.getters['riderdetails/getData']

    // fail safe
    if (!this.header) {
      await this.$store.dispatch('vehicledetails/fetchData', this.id)
      this.header = this.$store.getters['vehicledetails/getData']
    }

    await this.$store.dispatch(
      'lastTrip/saveStatus',
      this.header?.last_trip?.status ?? null
    )
    // alert(this.$store.getters["lastTrip/getStatus"]);
    // await this.$http
    //   .get(RiderUserConfig.api.single(this.id))
    //   .then(res => {
    //     console.log("rider-user-header", res);
    //     this.header = res.data;
    //   })
    //   .catch(err => {
    //     console.log("rider-user-header-err", err);
    //   });
  },
  mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    EventBus.$on('rider-details-header-refresh', () => {
      this.header = this.$store.getters['riderdetails/getData']
    })
  },
  data() {
    return {
      symbol: '$',
      org_user: {},
      fallbackText: '--',
      header: { ...RiderResource },
      summary: {
        total_revenue: 0,
        total_rider: 0,
        total_trip: 0,
        total_vehicle: 0,
      },
      tabs: [
        {
          text: this.$t('components.riderDetailsManagement.header.tab.profile'),
          href: this.getTabHref(`profile`),
        },
        {
          text: this.$t('components.riderDetailsManagement.header.tab.trip'),
          href: this.getTabHref(`trips`),
        },
        {
          text: this.$t('components.riderDetailsManagement.header.tab.rent'),
          href: this.getTabHref(`rents`),
        },
        {
          text: this.$t('components.riderDetailsManagement.header.tab.debit'),
          href: this.getTabHref(`debit-transactions`),
        },
        {
          text: this.$t('components.riderDetailsManagement.header.tab.credit'),
          href: this.getTabHref(`credit-transactions`),
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.header.tab.paymentMethod'
          ),
          href: this.getTabHref(`payments`),
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.header.tab.referrals'
          ),
          href: this.getTabHref(`referrals`),
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.header.tab.vouchers'
          ),
          href: this.getTabHref(`vouchers`),
        },
        {
          text: this.$t('components.riderDetailsManagement.header.tab.points'),
          href: this.getTabHref(`points`),
        },
        {
          text: this.$t('components.riderDetailsManagement.header.tab.pass'),
          href: this.getTabHref(`pass`),
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.header.tab.promotions'
          ),
          href: this.getTabHref(`promotions`),
        },
        {
          text: this.$t('components.riderDetailsManagement.header.tab.reports'),
          href: this.getTabHref(`reports`),
        },
        {
          text: this.$t('components.riderDetailsManagement.header.tab.logs'),
          href: this.getTabHref(`logs`),
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.header.tab.notifications'
          ),
          href: this.getTabHref(`notifications`),
        },
      ],
    }
  },
  // watch: {
  //   status(updated) {
  //     console.warn({ wathc: updated });
  //   }
  // },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    id() {
      return this.$route.params.id
    },
    getName() {
      return this.header ? this.header.full_name : ''
    },
    getBadge() {
      return this.header ? 'Rider' : ''
    },
    getBadgeVariant() {
      const aliases = {
        H: 'orange',
        A: 'green',
        D: 'red',
      }
      return this.header.user_status in aliases
        ? aliases[this.header.user_status]
        : 'fallback'
    },
    getAvatar() {
      return this.header ? this.header.profile.profile_pic : ''
    },
    getEmail() {
      return this.header ? this.header.email : ''
    },
    getPhone() {
      return this.header ? this.header.phone_number : ''
    },
    getStatus() {
      return ''
    },
    getSymbol() {
      return this.header ? this.header.currency.symbol : this.symbol
    },
    getStatusEndpoint() {
      return StatusConfig.api.rider(this.id)
    },
    getRiderSummary() {
      if (!this.header || !this.header.profile) return null
      const profile = this.header.profile
      const tripSummary = this.header.trip_summary
      return {
        total_balance: profile.balance,
        // total_trips: profile.num_of_trips,
        total_trips: tripSummary
          ? tripSummary.total_trip_count_with_reservation +
            tripSummary.total_trip_count_without_reservation
          : profile.num_of_trips,
        total_points: profile.total_points,
        total_calories: profile.total_calories,
      }
    },
    getLastTrip() {
      if (this.header && this.header.last_trip) {
        return this.header.last_trip
      }
      return {
        noLastTrip: true,
      }
    },
    getBlacklistedStatus() {
      return this.header.is_ip_blocked
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    getTabHref(path) {
      return `/riders/${this.$route.params.id}/${path}`
    },
    async UpdateStatus(status) {
      console.log({ id: this.id })
      console.log({ url: RiderUserConfig.api.update(this.id) })
      this.isLoading = true
      let data = new xMan({ user_status: status }).toFormData()
      await this.$http
        .patch(RiderUserConfig.api.update(this.id), data)
        .then((res) => {
          console.log({ r: res })
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Operator status has updated',
            },
            3000
          )
        })
        .catch((err) => {
          console.log('id-ver-err', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update status',
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
      return true
    },
  },
}
</script>
