<template>
  <section>
    <!-- <button v-if="!shouldDisplayDropdown" class="trip-status-button">
      <img :src="getIconSrc" class="w-8 h-8 overflow-hidden rounded-full" />
      <span class="ml-2 font-semibold" :class="getTextClass">
        {{ getStatusText }}
      </span>
    </button> -->

    <t-dropdown v-if="shouldDisplayDropdown" variant="smActions">
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          class="trip-status-button vld-parent"
          aria-label="Profile Status"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <loading
            :height="25"
            :opacity="0"
            color="#7d3cf8"
            v-if="isLoading"
            :active="true"
            loader="bars"
            :is-full-page="false"
          />

          <div
            v-else
            class="flex items-center justify-center w-8 h-8 bg-green-500 rounded-full"
          >
            <img :src="getIconSrc" />
          </div>

          <span class="ml-2 font-semibold" :class="getStatusClass">
            {{ getStatusText }}
          </span>
          <i class="pr-2 ml-auto text-lg text-gray-500 fas fa-chevron-down"></i>
        </button>
      </template>

      <template v-slot:default="{ hide }">
        <div @click="hide()" class="py-1 mt-1 bg-white rounded-md shadow-md">
          <t-dropdown-item @click="action('complete')">
            Force End
          </t-dropdown-item>
          <t-dropdown-item @click="action('cancel')">
            Force Cancel
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
  </section>
</template>

<script>
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import { TripConfig } from '@/config/TripConfig'
import { getTripStatusColor, getTripStatusText } from '@/utils/TripStatus'

export default {
  name: 'LastTripStatusDropdown',
  components: {
    TDropdownItem,
  },
  props: {
    lastTrip: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      currentStatus: this.lastTrip.status,
      statusData: {
        R: {
          icon: require('@/assets/img/flags/none.png'),
          text: 'On Reservation',
          class: 'text-blue-500',
        },
        A: {
          icon: require('@/assets/img/flags/none.png'),
          text: 'Cancelled',
          class: 'text-oRed',
        },
        U: {
          icon: require('@/assets/img/flags/none.png'),
          text: 'Starting Trip',
          class: 'text-oPurple',
        },
        O: {
          icon: require('@/assets/img/flags/on-ride.png'),
          text: 'On Rent',
          class: 'text-oPurple',
        },
        V: {
          icon: require('@/assets/img/flags/none.png'),
          text: 'Forcibly Completed',
          class: 'text-oOrange',
        },
        C: {
          icon: require('@/assets/img/flags/ok.png'),
          text: 'Completed',
          class: 'text-oGreen',
        },
        T: {
          icon: require('@/assets/img/flags/on-missing.png'),
          text: 'Time Out',
          class: 'text-oRed',
        },
      },
    }
  },
  watch: {
    lastTrip: {
      handler: function(updated) {
        console.warn(updated)
        this.currentStatus = updated.status
      },
      deep: true,
    },
  },
  computed: {
    lastTripStatus() {
      return this.$store.getters['lastTrip/getStatus']
    },
    shouldDisplayDropdown() {
      // const status = String(this.lastTrip.status)
      //   .replace(/\s/g, "-")
      //   .toLowerCase();
      const whitelist = ['R', 'O']
      // only display for whitelisted status
      return whitelist.indexOf(this.currentStatus) !== -1
    },
    getIconSrc() {
      return this.statusData[this.lastTrip.status]
        ? this.statusData[this.lastTrip.status].icon
        : require('@/assets/img/flags/none.png')
    },
    getTextClass() {
      return this.statusData[this.lastTrip.status]
        ? this.statusData[this.lastTrip.status].class
        : 'text-oGray'
    },
    getStatusText() {
      return this.getTripStatusText(this.lastTripStatus)
    },
    getStatusClass() {
      return this.statusData[this.lastTripStatus].class
    },
  },
  methods: {
    action(name) {
      const whitelist = ['cancel', 'complete']
      if (whitelist.indexOf(name) === -1 || !this.lastTrip.id) {
        this.$notify(
          {
            group: 'generic',
            type: 'danger',
            title: 'Action Execution Failed!',
            text: `The action is not permitted. [ERR:405]`,
          },
          5000
        )
        return
      }
      const status = {
        cancel: 'A',
        complete: 'C',
      }
      const titles = {
        cancel: 'Trip Ended',
        complete: 'Trip Completed',
      }
      const title = name in titles ? titles[name] : 'Action Executed'
      const titleLC = title.toLowerCase()

      this.isLoading = true
      let url = TripConfig.api.actions[name](this.lastTrip.id)
      this.$http
        .post(url)
        .then((res) => {
          console.warn(res)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: title,
              text: `The ${titleLC} successfully.`,
            },
            5000
          )
          this.currentStatus = status[name]
          this.$store.dispatch('lastTrip/saveStatus', this.currentStatus)
          // alert("lastDropdwn " + this.lastTripStatus);
          this.isLoading = false
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'generic',
              type: 'danger',
              title: 'Action Execution Failed!',
              text: `The action encountered an error from server. [ERR:${err.response.status}]`,
            },
            5000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getTripStatusColor,
    getTripStatusText,
  },
}
</script>

<style lang="scss" scoped>
.trip-status-button {
  width: 11rem;
  height: 42px;
  box-shadow: 0 3px 6px 0 rgba(61, 73, 82, 0.22);
  border: solid 1px #f5f5f5;

  @apply flex items-center justify-start px-1 py-2 text-sm border border-gray-200 rounded-full bg-white;

  &:focus {
    @apply outline-none;
  }
}
</style>
