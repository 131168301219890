import { useEndpoints } from '@/composables'

export const LocalData = [
  // vehicle
  {
    id: 'gs1',
    type: 'vehicle:',
    subText: 'qr code',
    example: 'vehicle:AF25F2',
  },
  {
    id: 'gs2',
    type: 'vehicle:',
    subText: 'IMEI',
    example: 'vehicle:860919123453422',
  },
  //trip
  {
    id: 'gs3',
    type: 'trip:',
    subText: 'last five digit of the associated trip id',
    example: 'trip:0d72f',
  },
  {
    id: 'gs4',
    type: 'trip:',
    subText: 'Rider phone number',
    example: 'trip:0167749',
  },
  {
    id: 'gs5',
    type: 'trip:',
    subText: 'Rider first name',
    example: 'trip:Masum',
  },
  {
    id: 'gs6',
    type: 'trip:',
    subText: 'Rider last name',
    example: 'trip:Khandaker',
  },
  {
    id: 'gs7',
    type: 'trip:',
    subText: 'Source Location Name',
    example: 'trip:Narsingdi',
  },
  {
    id: 'gs8',
    type: 'trip:',
    subText: 'Destination Location Name',
    example: 'trip:Dhaka',
  },
  //rental
  {
    id: 'gs9',
    type: 'rental:',
    subText: 'last five digit of the associated rental id',
    example: 'rental:554be',
  },
  //revenue
  {
    id: 'gs10',
    type: 'revenue:',
    subText: 'last five digit of the associated invoice id',
    example: 'revenue:3fb4f',
  },
  //payment
  {
    id: 'gs11',
    type: 'payment:',
    subText: 'last five digit of the associated invoice id',
    example: 'payment:81ed8',
  },
  //rider
  {
    id: 'gs12',
    type: 'rider:',
    subText: 'First Name',
    example: 'rider:Masum',
  },
  {
    id: 'gs13',
    type: 'rider:',
    subText: 'Last Name',
    example: 'rider:Khandaker',
  },
  {
    id: 'gs14',
    type: 'rider:',
    subText: 'Phone Number',
    example: 'rider:01677',
  },
  {
    id: 'gs15',
    type: 'rider:',
    subText: 'email',
    example: 'rider:masum@gmail.com',
  },
]

export const ApiResource = {
  trip: useEndpoints.trip.index(),
  vehicle: useEndpoints.vehicle.index() + '?bike_category=P',
  rental: useEndpoints.vehicleRental.index(),
  revenue: useEndpoints.invoice.indexOfRevenue(),
  payment: useEndpoints.invoice.indexOfPayment(),
  rider: useEndpoints.rider.index(),
}

export const RouteSources = {
  trip: 'ViewTrip',
  vehicle: 'ViewVehicleProfile',
  rental: 'VehicleRentalDetail',
}

export const TableHeaders = {
  trip: [
    { text: 'Id', width: '5%', sort: null },
    { text: 'Rider Phone Number', width: '15%', sort: null },
    { text: 'Pickup Time', width: '15%', sort: null },
    { text: 'Charge', width: '10%', sort: null },
    { text: 'Status', width: '10%', sort: null },
  ],
  vehicle: [
    { text: 'QR Code', width: '20%', sort: null },
    { text: 'IMEI', width: '30%', sort: null },
    { text: 'Last Connected', width: '20%', sort: null },
    { text: 'Flag', width: '30%', sort: null },
    { text: 'Status', width: '10%', sort: null },
  ],
  rental: [
    { text: 'Id', width: '5%', sort: null },
    { text: 'Rider Phone Number', width: '15%', sort: null },
    { text: 'Pickup Time', width: '15%', sort: null },
    { text: 'Charge', width: '10%', sort: null },
    { text: 'Status', width: '10%', sort: null },
  ],
  revenue: [
    { text: 'Invoice ID', width: '5%', sort: null },
    { text: 'Transaction Time', width: '15%', sort: null },
    { text: 'Payment For', width: '15%', sort: null },
    { text: 'Amount', width: '10%', sort: null },
    { text: 'Status', width: '10%', sort: null },
  ],
  payment: [
    { text: 'Invoice ID', width: '5%', sort: null },
    { text: 'Transaction Time', width: '15%', sort: null },
    { text: 'Payment For', width: '15%', sort: null },
    { text: 'Amount', width: '10%', sort: null },
    { text: 'Status', width: '10%', sort: null },
  ],
  rider: [
    { text: 'Name', width: '15%', sort: null },
    { text: 'Phone Number', width: '15%', sort: null },
    { text: 'Default Card', width: '15%', sort: null },
    { text: 'Balance', width: '10%', sort: null },
    { text: 'Last Trip Status', width: '10%', sort: null },
  ],
}

//--trip
export const getTooltipText = (status, isForced, isForcedBySystem) => {
  if (isForced) {
    if (isForcedBySystem) return 'Force Completed by system'
    else return 'Force Completed by Admin'
  } else if (status === 'A') {
    return "'Reservation Cancelled by Rider'"
  } else if (status === 'T') {
    return 'Reservation time out'
  } else if (status === 'UF') {
    return 'Unlocking Failed'
  }
}
export const getUserTripStatus = (status, isForced, isForcedBySystem) => {
  if (isForced && isForcedBySystem) return 'Completed'
  if (isForced && !isForcedBySystem) return 'Force End'
  let statuses = {
    R: 'Reserved',
    A: 'Cancelled',
    U: 'Starting',
    O: 'On Rent',
    V: 'Forced',
    C: 'Completed',
    T: 'Time Out',
    UF: 'Failed',
  }
  return status in statuses ? statuses[status] : '-'
}
export const getUserTripStatusBadge = (status, isForced, isForcedBySystem) => {
  if (isForced && isForcedBySystem) return 'ultra-green'
  if (isForced && !isForcedBySystem) return 'red'

  let badges = {
    R: 'blue',
    A: 'red',
    U: 'yellow',
    O: 'purple',
    V: 'red',
    C: 'green',
    T: 'red',
    UF: 'black',
  }
  let s = status in badges ? badges[status] : 'light-blue'

  return s
}

//--vehicle
export const getEnabledFlags = (item) => {
  let flags = {
    ...item.general_flags,
    ...item.operational_flags,
    ...item.status_flags,
  }
  // console.log(flags);
  let enabled = []

  for (const key in flags) {
    console.log('v-flag', key, flags[key])
    if (key !== 'is_idle' && flags[key] === true) {
      enabled.push(key)
    }
  }

  console.log('enabled', enabled)
  return enabled
}

export const getFlagAlias = (flag, data = {}) => {
  let aliases = {
    charging_pick: 'Charging Pick',
    maintainance: 'On Maintainance',
    rebalance: 'Rebalance',
    swap_battery: 'Swap Battery',
    hibernate_mode: 'Sleep Mode',

    restricted_alert: 'Restricted Alert',
    slowzone_alert: 'Slowzone Alert',
    geo_fence_alert: 'Geofence Alert',
    iot_fault: data.general_flags.fault_status_reason,
    low_battery: 'low Battery',
    missing: 'Missing',

    is_charging: 'On Charging',
    is_parking: 'On Parking',
    is_reserved: 'On Reservation',
    is_on_ride: 'On Ride',
    is_idle: 'Idle',

    none: 'None',
  }
  return flag in aliases ? aliases[flag] : '--'
}

// rental

export const shouldShowStatusTooltip = (status) => {
  return status === 'CANCELLED' || status === 'REJECTED'
}

export const getRentStatus = (item) => {
  const statuses = {
    REQUESTED: 'Pending',
    ACCEPTED: 'Accepted',
    CANCELLED: 'Cancelled',
    REJECTED: 'Rejected',
    TRIP_COMPLETED: 'Completed',
    DELIVERED: 'Rent Started',
  }
  if (item.status === 'TRIP_COMPLETED') {
    if (item.trip.status_forced) {
      return 'Force End'
    } else {
      return 'Completed'
    }
  } else {
    return statuses[item.status] || '--'
  }
}
export const getRentStatusBadge = (item) => {
  const badges = {
    REQUESTED: 'orange',
    ACCEPTED: 'blue',
    CANCELLED: 'red',
    REJECTED: 'blush',
    TRIP_COMPLETED: 'green',
    DELIVERED: 'purple',
  }
  if (item.status === 'TRIP_COMPLETED') {
    if (item.trip.status_forced) {
      return 'red'
    } else {
      return 'green'
    }
  } else {
    return badges[item.status] || 'blue'
  }
}

export const getTooltipTextForRent = ({
  status,
  cancel_reason,
  reject_reason,
}) => {
  const tooltipTexts = {
    CANCELLED: `Reason: ${cancel_reason || 'N/A'}`,
    REJECTED: `Reason: ${reject_reason || 'N/A'}`,
  }
  return tooltipTexts[status] || '--'
}
export const getCostLine = (item) => {
  if (!item.invoice || typeof item.invoice === 'string') {
    return '--'
  }

  return `${item.invoice.currency.symbol} ${item.invoice.amount}`
}

// revenue,payment
export const getPaymentForText = (type, textData) => {
  if (type === 'revenue') {
    if (textData.isArray) {
      return textData
    } else {
      const filterMap = [
        { text: 'Subscription Credit/Pass', value: 'Pass' },
        { text: 'Card Trip', value: 'Trip With Card' },
        { text: 'Balance deduct by admin', value: 'Rider Balance Adjust' },
      ]
      const selectedFilter = filterMap.filter((item) => item.value === textData)
      if (selectedFilter.length > 0) {
        return selectedFilter[0].text
      } else {
        return textData
      }
    }
  }
  if (type === 'payment') {
    if (textData.isArray) {
      return textData
    } else {
      const filterMap = [
        { text: 'Card Topup', value: 'Topup' },
        { text: 'Agent Topup', value: 'Agent Topup' },
        { text: 'Signup Bonus', value: 'Initial Balance' },
        {
          text: 'Referral Bonus by Invitee',
          value: 'Invitee Invitation Amount',
        },
        {
          text: 'Referral Bonus by Inviter',
          value: 'Inviter Invitation Amount',
        },
        {
          text: 'Balance Transfer by Admin',
          value: 'Rider Balance Adjust',
        },
      ]
      const selectedFilter = filterMap.filter((item) => item.value === textData)
      if (selectedFilter.length > 0) {
        return selectedFilter[0].text
      } else {
        return textData
      }
    }
  }
}

export const getPaymentCardIconClass = (card) => {
  console.log('card-details', card)
  const brand = card?.brand?.toLowerCase() || ''
  // get font awesome icon by card type
  let iconClass = 'fab '
  switch (brand?.toLowerCase()) {
    case 'visa':
      iconClass += 'fa-cc-visa'
      break
    case 'mastercard':
      iconClass += 'fa-cc-mastercard'
      break
    case 'americanexpress':
      iconClass += 'fa-cc-amex'
      break
    case 'discover':
      iconClass += 'fa-cc-discover'
      break
    case 'jcb':
      iconClass += 'fa-cc-jcb'
      break
    case 'dinersclub':
      iconClass += 'fa-cc-diners-club'
      break
    default:
      iconClass = 'fas fa-wallet'
      break
  }
  return iconClass
}

export const getRiderTripStatus = (status) => {
  const statuses = {
    N: 'Completed',
    R: 'On Reserved',
    T: 'On Rent',
    P: 'On Pay',
    F: 'On Feedback',
  }
  return status in statuses ? statuses[status] : '--'
}
export const getRiderTripStatusBadge = (status) => {
  const badges = {
    N: 'green',
    R: 'orange',
    T: 'purple',
    P: 'yellow',
    F: 'blue',
  }
  return status in badges ? badges[status] : 'gray'
}
